<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Postdated Check Deposit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text to="/postdated-checks">
          <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor @data="save"></editor>
        <!-- end -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  methods: {
    save() {},
  },
};
</script>

<style lang="scss" scoped>
</style>